%l-flex {
    display: flex;
    overflow: hidden;
  }
  
  .l-row {
    @extend %l-flex;
  }
  .l-row-wrap {
    @extend %l-flex;
    flex-wrap: wrap;
  }
  .l-row-center {
    @extend %l-flex;
    align-items: center;
  }
  .l-column {
    @extend %l-flex;
    flex-direction: column;
  }
  
  
  .l-row-reverse {
    @extend %l-flex;
    flex-direction: row-reverse;
  }
  .l-column-reverse {
    @extend %l-flex;
    flex-direction: column-reverse;
  }
  
  .l-preserve {
    flex: 0 0 auto;
    min-width: 0;
    overflow: visible;
    text-overflow: inherit;
  }
  
  %l-fill {
    flex-grow: 1;
    min-height: 0;
    min-width: 0;
  }
  
  .l-fill {
    @extend %l-fill;
    &--scroll-y {
      @extend %l-fill;
      overflow-x: hidden;
      overflow-y: auto;
    }
    &--scroll-x {
      @extend %l-fill;
      overflow-x: auto;
      overflow-y: hidden;
    }
    &--scroll-xy {
      @extend %l-fill;
      overflow-x: auto;
      overflow-y: auto;
    }
  }
  
  //scrollbar
  
  /* width */
  ::-webkit-scrollbar {
    width: 6px !important;
    height: 5px !important;
  }
  ::-webkit-scrollbar:hover {
    width: 10px!important;
  }

  // /* Simplebar */
  .simplebar-scrollbar{
    width: 10px!important;
  }
  .simplebar-scrollbar::before {
    background-color:#787878!important;
  }
  .simplebar-track.simplebar-vertical {
  width: 20px;
  }
  
  .simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
  //left: 10px;
  transition: opacity 0s linear, left 0.2s ease-in-out;
  }
  
  .simplebar-track.simplebar-vertical.simplebar-hover .simplebar-scrollbar::before,
  .simplebar-dragging .simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
  right: 0px;
  left: 0px;
  }
  
  // ngx-simplebar{
  //   left: 1px;
  // }
  .simplebar-scrollbar::before {
    display: none;
  //  height: 300px!important;
  }
  ngx-simplebar:hover{
    .simplebar-scrollbar::before {
      display: inline-block;
    //  height: 300px!important;
    }
  }
   /* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
  margin-top:3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(180, 180, 180) !important;
  border-radius: 10px !important;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(136, 136, 136) !important;

}
