:root{
    --primary : #00274e;
    --secondary: #a03f1d;
    --black: #000;
    --white: #fff;
    --error: #d32f2f;
    --error20: #F6D5D5;
    --warning: #f9a825;
    --warning20: #FEEED3;
    --success: #4caf50;
    --success20: #DBEFDC;
    --info: #2685ca;    
    --info20: #D4E7F4;
    --highlight: #007FC5;
    --highlightBG: #F2F9FC;
    --gray100: #273138;
    --gray-100: #000;
    --gray80: #4b5961;
    --gray-80: #58606E;
    --gray60: #738289;
    --gray40: #9daab0;
    --gray20: #ccd4d8;
    --gray05: #f4f4f5;
    --gray03: #828282;
    --gray01:#333333;
    --q-color-primary: #257099;
    --blue:#DBEAFF;
    --lavender: #EFDAFF;
    --orange: #FFEDCD;
    --teal: #C9F5F9;
    --green: #CCF2DE;
    --gray: #E5E5E5;  
    --dark-gray: #666666;  
    --brandBlue: #093C70;
    --highlight-light-bg: #F2F9FC;
    --brandOrange: #FEA408;
    --brandRed: #D93B3B;
    --warning100: #FBCB7C;
}
.ion-color-blue{
    --ion-color-base:var(--blue);
}  
.ion-color-gray{
    --ion-color-base:var(--gray);
}
.ion-color-orange{
    --ion-color-base:var(--orange);
}
.ion-color-lavender{
    --ion-color-base:var(--lavender);
}  
.ion-color-brandOrange{
    --ion-color-base:var(--brandOrange);
}
.ion-color-brandRed{
    --ion-color-base:var(--brandRed);
}
.ion-color-dark-gray{
    --ion-color-base:var(--dark-gray);
}  
.ion-color-teal{
    --ion-color-base:var(--teal);
} 
.ion-color-gray-80{
    --ion-color-base:var(--gray-80);
}
.ion-color-white {
    --ion-color-base: var(--white);
}
.ion-color-error{
    --ion-color-base: var(--error)
}
.ion-color-light-highlight{
    --ion-color-base: var(--highlight-light-bg)
}
.ion-color-info20{
    --ion-color-base: var(--info20)
}
.ion-color-highlight{
    --ion-color-base: var(var(--highlight))
}
.ion-color-success{
    --ion-color-base: var(--success)
}
.ion-color-primary{
    --ion-color-base: var(--q-color-primary)
}
.ion-color-gray100{
    --ion-color-base: var(--gray100)
}
.ion-color-brandBlue{
    --ion-color-base: var(--brandBlue)
}
.ion-color-gray-100{
    --ion-color-base: var(--gray-100)
}
.ion-color-warning100{
    --ion-color-base: var(var(--warning100))
}



@each $key, $val in $colors{
    .text-#{$key}{
        color: $val;
    }

    .text-hover-#{$key}{
        &:hover{
            color: $val;
        }
    }
    .bg-#{$key}{
        background-color: $val;
    }

    .bg-hover-#{$key}{
        &:hover{
            background-color: $val;
        }
    }

    

    @if($val != black and $val != white){
        //light variations
        @for $i from 1 through 9 {
            .text-#{$key}-light-#{$i} {
                color: mix(white, $val, $i * 10);
            }
    
            .text-hover-#{$key}-light-#{$i} {
                &:hover{
                    color: mix(white, $val, $i * 10);
                }
            }

            .bg-#{$key}-light-#{$i} {
                background-color: mix(white, $val, $i * 10);
            }
            .bg-hover-#{$key}-light-#{$i} {
                &:hover{
                    background-color: mix(white, $val, $i * 10);
                }
            }
    
        }

        //dark variations
        @for $i from 1 through 9 {
            .text-#{$key}-dark-#{$i} {
                color: mix(black, $val, $i * 10);
            }
            .text-hover-#{$key}-dark-#{$i} {
                &:hover{
                    color: mix(black, $val, $i * 10);
                }
                
            }
            .bg-#{$key}-dark-#{$i} {
                background-color: mix(black, $val, $i * 10);
            }
            .bg-hover-#{$key}-dark-#{$i} {
                &:hover{
                    background-color: mix(black, $val, $i * 10);
                }
            }
        }
    }
    
}

