// Background Only

// the ionic color="light / medium / dark" are focused on FONT COLOR.
// ie - what we consider "light" is much darker that we would want as a "light" background color.
// Here, we define what light, medium, dark background colors would be. They are a much lighter shade than their text color based counterparts.

.background-color-light {
  background-color: var(--q-background-color--light);
}

.background-color-medium {
  background-color: var(--q-background-color--medium);
}

.background-color-dark {
  background-color: var(--q-background-color--dark);
}

// Color (Font) Only

.color-light-contrast {
  color: var(--q-background-color--light--contrast);
}

.color-medium-contrast {
  color: var(--q-background-color--medium--contrast);
}

.color-dark-contrast {
  color: var(--q-background-color--dark--contrast);
}

// Background and Color Both

.colors-light {
  background-color: var(--q-background-color--light);
  color: var(--q-background-color--light--contrast);
}

.colors-medium {
  background-color: var(--q-background-color--medium);
  color: var(--q-background-color--medium--contrast);
}

.colors-dark {
  background-color: var(--q-background-color--dark);
  color: var(--q-background-color--dark--contrast);
}
