// UI / Application

:root {
	// --q-app-background-color: hsl(205deg 8% 95%); ///	Background color of the entire app
	--q-app-background-color: #FFF; ///	Background color of the entire app
	--q-app-background-color-rgb: 241, 242, 243; ///	Background color of the entire app, rgb format
	--q-app-text-color: #111111; ///	Text color of the entire app
	--q-app-text-color-rgb: 17, 17, 17; ///	Text color of the entire app, rgb format

	--q-app-banner-background-color: #FFF; // Main top banner of the app
	--q-app-sidebar-background-color: #FFF; // Primary Sidebar / mobile footer
	--q-app--border-radius: 1rem; // default border radius for rounded UI elements
	--q-app-transition: all ease-in-out .2s;

  --q-app-space--xxs: .3rem;
	--q-app-space--xs: .5rem;
	--q-app-space--sm: 1rem;
	--q-app-space--md: 1.6rem; // default margin/padding
	--q-app-space: var(--q-app-space--md); // default
	--q-app-space--lg: 2rem;
	--q-app-space--xl: 4rem;
}

:root {
	// borders used throughout app

	--q-app--border-color-light: rgba(0, 0, 0, 0.02); // lightest
	--q-app--border-color-medium: rgba(0, 0, 0, 0.13);
	--q-app--border-color: var(--q-app--border-color-medium);
	--q-app--border-color-dark: rgba(0, 0, 0, .4);

	--q-app--border-light: solid 1px var(--q-app--border-color-light);
	--q-app--border-medium: solid 1px var(--q-app--border-color-medium);
	--q-app--border: var(--q-app--border-medium);
	--q-app--border-dark: solid 1px var(--q-app--border-color-dark);
}

/////////////////////////////////////////////////////////////////////////////
// Primary Theming / Brand
:root {

	//  Very Common - Links / Highlight / Active
	--q-color-highlight: #007FC5;

	// // Common - used on main elements
	--q-color-secondary: #257099;
	--q-color-secondary-rgb: 37, 112, 153;
	--q-color-secondary-contrast: #ffffff;
	--q-color-secondary-contrast-rgb: 255, 255, 255;
	--q-color-secondary-shade: #00274e;
	--q-color-secondary-tint: #518dad;

	// call to action, etc - uncommon
	--q-color-tertiary: #e96127;
	--q-color-tertiary-rgb: 160, 63, 29;
	--q-color-tertiary-contrast: #ffffff;
	--q-color-tertiary-contrast-rgb: 255, 255, 255;
	--q-color-tertiary-shade: #a03f1d;
	--q-color-tertiary-tint: #ed8152;

	// ///////////////////////////////
	// UI SHADES - light,medium,dark

	// NOTE - These are TEXT COLOR Based. Background color lighter shades are set as custom variables and classes below.

	--q-color-dark: #151515;
	--q-color-dark-rgb: 21, 21, 21;
	--q-color-dark-contrast: #ffffff;
	--q-color-dark-contrast-rgb: 255, 255, 255;
	--q-color-dark-shade: #121212;
	--q-color-dark-tint: #2c2c2c;

	--q-color-medium: #4b5961;
	--q-color-medium-rgb: 75, 89, 97;
	--q-color-medium-contrast: #ffffff;
	--q-color-medium-contrast-rgb: 255, 255, 255;
	--q-color-medium-shade: #424e55;
	--q-color-medium-tint: #5d6a71;

	--q-color-light: #738289;
	--q-color-light-rgb: 115, 130, 137;
	--q-color-light-contrast: #ffffff;
	--q-color-light-contrast-rgb: 255, 255, 255;
	--q-color-light-shade: #657279;
	--q-color-light-tint: #818f95;

	// ///////////////////////////////
	// ALERT COLORS

	--q-color-success: #4caf50;
	--q-color-success-rgb: 76, 175, 80;
	--q-color-success-contrast: rgba(0, 0, 0, .8);
	--q-color-success-contrast-rgb: 0, 0, 0;
	--q-color-success-shade: #439a46;
	--q-color-success-tint: #5eb762;

	--q-color-warning: #f9a825;
	--q-color-warning-rgb: 249, 168, 37;
	--q-color-warning-contrast: rgba(0, 0, 0, .8);
	--q-color-warning-contrast-rgb: 0, 0, 0;
	--q-color-warning-shade: #db9421;
	--q-color-warning-tint: #fab13b;

	--q-color-danger: #d32f2f;
	--q-color-danger-rgb: 211, 47, 47;
	--q-color-danger-contrast: #ffffff;
	--q-color-danger-contrast-rgb: 255, 255, 255;
	--q-color-danger-shade: #ba2929;
	--q-color-danger-tint: #d74444;

	--q-color-error60: #e58282;
	--q-color-error60-rgb: 229, 130, 130;
	--q-color-error60-contrast: #000000;
	--q-color-error60-contrast-rgb: 0, 0, 0;
	--q-color-error60-shade: #ca7272;
	--q-color-error60-tint: #e88f8f;

	--q-color-gray20: #ccd4d8;
	--q-color-gray20-rgb: 204, 212, 216;
	--q-color-gray20-contrast: #000000;
	--q-color-gray20-contrast-rgb: 0, 0, 0;
	--q-color-gray20-shade: #b4bbbe;
	--q-color-gray20-tint: #d1d8dc;

  --q-color-gray80: #4B5961;
	--q-color-gray80-rgb: 75,89,97;
	--q-color-gray80-contrast: #ffffff;
	--q-color-gray80-contrast-rgb: 255,255,255;
	--q-color-gray80-shade: #424e55;
	--q-color-gray80-tint: #5d6a71;

  --q-color-gray60: #738289;
	--q-color-gray60-rgb: 115,130,137;
	--q-color-gray60-contrast: #ffffff;
	--q-color-gray60-contrast-rgb: 255,255,255;
	--q-color-gray60-shade: #657279;
	--q-color-gray60-tint: #818f95;

	--q-color-gray100: #273138;

	
}

.ion-color-highlight {
	--ion-color-base: var(--ion-color-highlight);
}

// Shades / etc
:root {
	--q-color-gray-step-05: #F4F4F5;
	--q-color-gray-step-50: #f4f5f5;
	--q-color-gray-step-100: #e9eaeb;
	--q-color-gray-step-150: #dfe0e1;
	--q-color-gray-step-200: #ccd4d8;
	--q-color-gray-step-250: #c9cccd;
	--q-color-gray-step-300: #bec1c3;
	--q-color-gray-step-350: #b3b7b9;
	--q-color-gray-step-400: #9daab0;
	--q-color-gray-step-450: #9ea2a5;
	--q-color-gray-step-500: #93989c;
	--q-color-gray-step-550: #888e92;
	--q-color-gray-step-600: #7d8388;
	--q-color-gray-step-650: #73797e;
	--q-color-gray-step-700: #686f74;
	--q-color-gray-step-750: #5d656a;
	--q-color-gray-step-800: #4b5961;
	--q-color-gray-step-850: #475056;
	--q-color-gray-step-900: #3d464c;
	--q-color-gray-step-950: #273138;
	--q-color-gray-step-1000: #000;
}