// Theming Basics

// https://ionicframework.com/docs/theming/basics
// Ionic has nine default colors that can be used to change the color of many components. Each color is actually a collection of multiple properties, including a shade and tint, used throughout Ionic.

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////
:root {

	//  Very Common - Links / Highlight / Active
	--ion-color-highlight: var(--q-color-highlight);

	--ion-color-primary: var(--q-color-primary);
	--ion-color-primary-rgb: var(--q-color-primary-rgb);
	--ion-color-primary-contrast: var(--q-color-primary-contrast);
	--ion-color-primary-contrast-rgb: var(--q-color-primary-contrast-rgb);
	--ion-color-primary-shade: var(--q-color-primary-shade);
	--ion-color-primary-tint: var(--q-color-primary-tint);


	// // Common - used on main elements
	--ion-color-secondary: var(--q-color-secondary);
	--ion-color-secondary-rgb: var(--q-color-secondary-rgb);
	--ion-color-secondary-contrast: var(--q-color-secondary-contrast);
	--ion-color-secondary-contrast-rgb: var(--q-color-secondary-contrast-rgb);
	--ion-color-secondary-shade: var(--q-color-secondary-shade);
	--ion-color-secondary-tint: var(--q-color-secondary-tint);

	// call to action, etc - uncommon
	--ion-color-tertiary: var(--q-color-tertiary);
	--ion-color-tertiary-rgb: var(--q-color-tertiary-rgb);
	--ion-color-tertiary-contrast: var(--q-color-tertiary-contrast);
	--ion-color-tertiary-contrast-rgb: var(--q-color-tertiary-contrast-rgb);
	--ion-color-tertiary-shade: var(--q-color-tertiary-shade);
	--ion-color-tertiary-tint: var(--q-color-tertiary-tint);

	// ///////////////////////////////
	// UI SHADES - light,medium,dark
	--ion-color-dark: var(--q-color-dark);
	--ion-color-dark-rgb: var(--q-color-dark-rgb);
	--ion-color-dark-contrast: var(--q-color-dark-contrast);
	--ion-color-dark-contrast-rgb: var(--q-color-dark-contrast-rgb);
	--ion-color-dark-shade: var(--q-color-dark-shade);
	--ion-color-dark-tint: var(--q-color-dark-tint);

	--ion-color-medium: var(--q-color-medium);
	--ion-color-medium-rgb: var(--q-color-medium-rgb);
	--ion-color-medium-contrast: var(--q-color-medium-contrast);
	--ion-color-medium-contrast-rgb: var(--q-color-medium-contrast-rgb);
	--ion-color-medium-shade: var(--q-color-medium-shade);
	--ion-color-medium-tint: var(--q-color-medium-tint);

	--ion-color-light: var(--q-color-light);
	--ion-color-light-rgb: var(--q-color-light-rgb);
	--ion-color-light-contrast: var(--q-color-light-contrast);
	--ion-color-light-contrast-rgb: var(--q-color-light-contrast-rgb);
	--ion-color-light-shade: var(--q-color-light-shade);
	--ion-color-light-tint: var(--q-color-light-tint);

	// ///////////////////////////////
	// ALERT COLORS

	--ion-color-success: var(--q-color-success);
	--ion-color-success-rgb: var(--q-color-success-rgb);
	--ion-color-success-contrast: var(--q-color-success-contrast);
	--ion-color-success-contrast-rgb: var(--q-color-success-contrast-rgb);
	--ion-color-success-shade: var(--q-color-success-shade);
	--ion-color-success-tint: var(--q-color-success-tint);

	--ion-color-warning: var(--q-color-warning);
	--ion-color-warning-rgb: var(--q-color-warning-rgb);
	--ion-color-warning-contrast: var(--q-color-warning-contrast);
	--ion-color-warning-contrast-rgb: var(--q-color-warning-contrast-rgb);
	--ion-color-warning-shade: var(--q-color-warning-shade);
	--ion-color-warning-tint: var(--q-color-warning-tint);

	--ion-color-danger: var(--q-color-danger);
	--ion-color-danger-rgb: var(--q-color-danger-rgb);
	--ion-color-danger-contrast: var(--q-color-danger-contrast);
	--ion-color-danger-contrast-rgb: var(--q-color-danger-contrast-rgb);
	--ion-color-danger-shade: var(--q-color-danger-shade);
	--ion-color-danger-tint: var(--q-color-danger-tint);

	--ion-color-gray20: #ccd4d8;
	--ion-color-gray20-rgb: 204, 212, 216;
	--ion-color-gray20-contrast: #000000;
	--ion-color-gray20-contrast-rgb: 0, 0, 0;
	--ion-color-gray20-shade: #b4bbbe;
	--ion-color-gray20-tint: #d1d8dc;

	--ion-color-error60: #e58282;
	--ion-color-error60-rgb: 229, 130, 130;
	--ion-color-error60-contrast: #000000;
	--ion-color-error60-contrast-rgb: 0, 0, 0;
	--ion-color-error60-shade: #ca7272;
	--ion-color-error60-tint: #e88f8f;

  --ion-color-gray80: #4B5961;
	--ion-color-gray80-rgb: 75,89,97;
	--ion-color-gray80-contrast: #ffffff;
	--ion-color-gray80-contrast-rgb: 255,255,255;
	--ion-color-gray80-shade: #424e55;
	--ion-color-gray80-tint: #5d6a71;

  --ion-color-gray100: var(--q-color-gray-step-950);

  --ion-color-gray40: #9DAAB0;

  --ion-color-gray01: #333333;

  --ion-color-gray05: #F4F4F5;

  --ion-color-gray60: #738289;
	--ion-color-gray60-rgb: 115,130,137;
	--ion-color-gray60-contrast: #ffffff;
	--ion-color-gray60-contrast-rgb: 255,255,255;
	--ion-color-gray60-shade: #657279;
	--ion-color-gray60-tint: #818f95;
}

.ion-color-error60 {
	--ion-color-base: var(--ion-color-error60);
	--ion-color-base-rgb: var(--ion-color-error60-rgb);
	--ion-color-contrast: var(--ion-color-error60-contrast);
	--ion-color-contrast-rgb: var(--ion-color-error60-contrast-rgb);
	--ion-color-shade: var(--ion-color-error60-shade);
	--ion-color-tint: var(--ion-color-error60-tint);
}

.ion-color-gray20 {
	--ion-color-base: var(--ion-color-gray20);
	--ion-color-base-rgb: var(--ion-color-gray20-rgb);
	--ion-color-contrast: var(--ion-color-gray20-contrast);
	--ion-color-contrast-rgb: var(--ion-color-gray20-contrast-rgb);
	--ion-color-shade: var(--ion-color-gray20-shade);
	--ion-color-tint: var(--ion-color-gray20-tint);
}

.ion-color-gray80 {
	--ion-color-base: var(--ion-color-gray80);
	--ion-color-base-rgb: var(--ion-color-gray80-rgb);
	--ion-color-contrast: var(--ion-color-gray80-contrast);
	--ion-color-contrast-rgb: var(--ion-color-gray80-contrast-rgb);
	--ion-color-shade: var(--ion-color-gray80-shade);
	--ion-color-tint: var(--ion-color-gray80-tint);
}

.ion-color-gray60 {
	--ion-color-base: var(--ion-color-gray60);
	--ion-color-base-rgb: var(--ion-color-gray60-rgb);
	--ion-color-contrast: var(--ion-color-gray60-contrast);
	--ion-color-contrast-rgb: var(--ion-color-gray60-contrast-rgb);
	--ion-color-shade: var(--ion-color-gray60-shade);
	--ion-color-tint: var(--ion-color-gray60-tint);
}

.ion-color-gray100{
  --ion-color-base: var(--q-color-gray-step-950);
}

.ion-color-gray40 {
	--ion-color-base: var(--q-color-gray-step-400);
}

.ion-color-gray20 {
	--ion-color-base: var(--ion-color-gray20);
}

.highlight{
	--ion-base-color: var(--q-color-highlight);
}

.gray01{
	--ion-base-color: var(--ion-color-gray01);
}

.gray05{
	--ion-base-color: var(--ion-color-gray05);
}