@use 'sass:math';

.card{
    display: inline-block;
    padding: 0.938rem 0.625rem;
    background-color: var(--white);

    .card-title{
        font-size: $font-size-lg;
        padding-bottom: $base_padding;
        font-weight: bold;
    }

    .card-body{
        font-size: $base-font-size;

        a{
            text-decoration: none;
            color: $primary;
        }
    }
}

