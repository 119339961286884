
// Can be generated at https://ionicframework.com/docs/theming/themes#stepped-colors
// While updating the background (--ion-background-color) and text (--ion-text-color) variables will change the look of the app for most components, there are certain Ionic components where it may look off, or broken. This will be more apparent when applying a darker theme.


:root {
	--ion-color-primary-step-50: #f4f8fa;
	--ion-color-primary-step-100: #e9f1f5;
	--ion-color-primary-step-150: #deeaf0;
	--ion-color-primary-step-200: #d3e2eb;
	--ion-color-primary-step-250: #c9dbe6;
	--ion-color-primary-step-300: #bed4e0;
	--ion-color-primary-step-350: #b3cddb;
	--ion-color-primary-step-400: #a8c6d6;
	--ion-color-primary-step-450: #9dbfd1;
	--ion-color-primary-step-500: #92b8cc;
	--ion-color-primary-step-550: #87b0c7;
	--ion-color-primary-step-600: #7ca9c2;
	--ion-color-primary-step-650: #71a2bd;
	--ion-color-primary-step-700: #669bb8;
	--ion-color-primary-step-750: #5c94b3;
	--ion-color-primary-step-800: #518dad;
	--ion-color-primary-step-850: #4685a8;
	--ion-color-primary-step-900: #3b7ea3;
	--ion-color-primary-step-950: #30779e;

  --ion-color-secondary-step-50: #f2f9fc;
	--ion-color-secondary-step-100: #e6f2f9;
	--ion-color-secondary-step-150: #d9ecf6;
	--ion-color-secondary-step-200: #cce5f3;
	--ion-color-secondary-step-250: #bfdff1;
	--ion-color-secondary-step-300: #b3d9ee;
	--ion-color-secondary-step-350: #a6d2eb;
	--ion-color-secondary-step-400: #99cce8;
	--ion-color-secondary-step-450: #8cc5e5;
	--ion-color-secondary-step-500: #80bfe2;
	--ion-color-secondary-step-550: #73b9df;
	--ion-color-secondary-step-600: #66b2dc;
	--ion-color-secondary-step-650: #59acd9;
	--ion-color-secondary-step-700: #4da5d6;
	--ion-color-secondary-step-750: #409fd4;
	--ion-color-secondary-step-800: #3399d1;
	--ion-color-secondary-step-850: #2692ce;
	--ion-color-secondary-step-900: #198ccb;
	--ion-color-secondary-step-950: #0d85c8;

  --ion-color-tertiary-step-50: #fef7f4;
	--ion-color-tertiary-step-100: #fdefe9;
	--ion-color-tertiary-step-150: #fce7df;
	--ion-color-tertiary-step-200: #fbdfd4;
	--ion-color-tertiary-step-250: #fad8c9;
	--ion-color-tertiary-step-300: #f8d0be;
	--ion-color-tertiary-step-350: #f7c8b3;
	--ion-color-tertiary-step-400: #f6c0a9;
	--ion-color-tertiary-step-450: #f5b89e;
	--ion-color-tertiary-step-500: #f4b093;
	--ion-color-tertiary-step-550: #f3a888;
	--ion-color-tertiary-step-600: #f2a07d;
	--ion-color-tertiary-step-650: #f19873;
	--ion-color-tertiary-step-700: #f09068;
	--ion-color-tertiary-step-750: #ef895d;
	--ion-color-tertiary-step-800: #ed8152;
	--ion-color-tertiary-step-850: #ec7947;
	--ion-color-tertiary-step-900: #eb713d;
	--ion-color-tertiary-step-950: #ea6932;

	--ion-color-medium-step-50: #c4ccd0;
	--ion-color-medium-step-100: #bcc4c9;
	--ion-color-medium-step-150: #b4bcc1;
	--ion-color-medium-step-200: #acb5ba;
	--ion-color-medium-step-250: #a4adb2;
	--ion-color-medium-step-300: #9ca5aa;
	--ion-color-medium-step-350: #949da3;
	--ion-color-medium-step-400: #8c959b;
	--ion-color-medium-step-450: #848d94;
	--ion-color-medium-step-500: #7c868c;
	--ion-color-medium-step-550: #747e84;
	--ion-color-medium-step-600: #6c767d;
	--ion-color-medium-step-650: #646e75;
	--ion-color-medium-step-700: #5c666e;
	--ion-color-medium-step-750: #545e66;
	--ion-color-medium-step-800: #4c565e;
	--ion-color-medium-step-850: #444f57;
	--ion-color-medium-step-900: #3c474f;
	--ion-color-medium-step-950: #343f48;

}
