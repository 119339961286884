
.k-popup {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

.k-popover {
  background-color: #000;
  color: #FFF;
  font-size: var(--q-font-size--body);
  line-height: inherit;

  &-header,
  &-footer,
  &-body {
    padding: 1rem calc(var(--ion-safe-area-right, 0) + 16px) 2rem calc(var(--ion-safe-area-left, 0) + 16px);
  }

  &-header {
    color: #FFF;
  }

  &-callout {
    background-color: #000;
  }
}

