@mixin btn($bg-color: #e2e2e2) {
        text-decoration: none;
        cursor: pointer;
        display: inline-block;
        border: 0;
        padding: $base_padding $base_padding * 2;
        border-radius: $base-border-radius;
        background-color: $bg-color;
}

.btn {
    @include btn;
}


@each $key, $val in $colors {
    .btn-#{$key} {
        @include btn($val);

        &:hover{
            background-color: lighten($val, 5);
        }
    }

    .btn-outlined-#{$key} {
        @include btn($white);
        border: $base-border-thickness solid $val;
        &:hover{
            background-color: $val;
        }
    }

    .btn-complement-#{$key}{
        @include btn($val);
        color: light-comp($val);
        &:hover{
            color: $val;
            background-color: light-comp($val);
        }

    }
}

ion-button.btn-padding {
    --padding-start: 20px;
    --padding-end: 20px;
    --padding-top: 10px;
    --padding-bottom: 10px;
}