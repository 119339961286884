ion-avatar {
  &.is-xs {
    width: 1.8rem;
    height: 1.8rem;
  }
  &.is-md {
    width: 4rem;
    height: 4rem;
  }
}
