:root {
  --q-color-brand: #246f99;
	--q-color-brand-rgb: 36,111,153;
	--q-color-brand-contrast: #ffffff;
	--q-color-brand-contrast-rgb: 255,255,255;
	--q-color-brand-shade: #206287;
	--q-color-brand-tint: #3a7da3;
}

.ion-color-brand {
  --ion-color-base: var(--q-color-brand);
  --ion-color-base-rgb: var(--q-color-brand-rgb);
  --ion-color-contrast: var(--q-color-brand-contrast);
  --ion-color-contrast-rgb: var(--q-color-brand-contrast-rgb);
  --ion-color-shade: var(--q-color-brand-shade);
  --ion-color-tint: var(--q-color-brand-tint);
}

.ion-color-dark {
	--ion-color-base: var(--ion-color-dark);
	--ion-color-base-rgb: var(--ion-color-dark-rgb);
	--ion-color-contrast: var(--ion-color-dark-contrast);
	--ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb);
	--ion-color-shade: var(--ion-color-dark-shade);
	--ion-color-tint: var(--ion-color-dark-tint);
}
