// theme colors
$primary : #00274e;
$secondary: #a03f1d;
$gray100: #273138;
$black: #000;
$white: #fff;
$error: #d32f2f;
$warning: #f9a825;
$success: #4caf50;
$info: #2685ca;    
$highlight: #007FC5;

// color palette
$colors: (
    "primary" : $primary,
    "secondary" : $secondary,
    "error" : $error,
    "info" : $info,
    "warning" : $warning,
    "success" : $success,
    "black" : $black,
    "white" : $white,
    "gray100" : $gray100,
    "highlight": $highlight,
);

@debug map-get($map: $colors, $key: "secondary");


// borders
$base-border-thickness: 1px;
$base-border-radius: 10px;

// box-shadow
$base-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.20), 0px 4px 4px 0px rgba(0, 0, 0, 0.20);

//font sizes
$base-font-size : 1rem; // 16px
$font-size-xs: $base-font-size * 0.63; // 10px
$font-size-sm : $base-font-size * 0.75; // 12px
$font-size-md : $base-font-size * 1.125; // 18px
$font-size-lg : $base-font-size * 1.5; //  24px
$font-size-xl : $base-font-size * 2; // 32px
$font-size-xxl : $base-font-size * 3; // 48px
$font-size-xxxl : $base-font-size * 4; // 64px
$font-size-header : 1.25rem; // 20px
$font-text : 0.875rem; // 14px


//margin
.mx-0{
    margin-left: 0;
    margin-right: 0;
}

.my-0{
    margin-top: 0;
    margin-bottom: 0;
}

.px-0{
    padding-left: 0;
    padding-right: 0;
}

.py-0{
    padding-top: 0;
    padding-bottom: 0;
}

.mx-md{
    margin-left: 0.938rem;
    margin-right: 0.938rem;
}

.my-md{
    margin-top: 0.938rem;
    margin-bottom: 0.938rem;
}

.px-md{
    padding-left: 0.938rem;
    padding-right: 0.938rem;
}

.py-md{
    padding-top: 0.938rem;
    padding-bottom: 0.938rem;
}

.mx-sm{
    margin-left: 0.625rem;
    margin-right: 0.625rem;
}

.my-sm{
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
}

.px-sm{
    padding-left: 0.625rem;
    padding-right: 0.625rem;
}

.py-sm{
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}

.mx-lg{
    margin-left: 1.25rem;
    margin-right: 1.25rem;
}

.my-lg{
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
}

.px-lg{
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.py-lg{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}

.pt-lg{
    padding-top: 1.25rem;
}

.pb-lg{
    padding-bottom: 1.25rem;
}