ion-item {
  &.item-selected {
 //   -webkit-mask-image: linear-gradient(to left, transparent, white), linear-gradient(to left, transparent 10px, #FFF 10px);
    --ion-item-background: var(--q-color-primary); ///	Background of the Item
    --ion-item-color: var(--q-color-primary-contrast); ///	Color of the components in the Item

    // commonly nested items color overrides 
    a {
      color: var(--q-color-primary-contrast)
    }
  }
}


// ion-item {
//   & > ion-grid {
//     // --ion-grid-padding: 0;
//     --ion-grid-column-padding: 0;
//     --ion-grid-row-padding: 0;
//   }
// }