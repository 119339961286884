@mixin bg_circle($bg:transperent, $c:var(--gray40), $bc:var(--gray40)){
    height: 34px;
    width: 34px;
    background-color: $bg;
    text-align: center;
    line-height: 40px;
    display: block;
    border-radius: 50%;
    color: $c;
    border: 1px solid $bc;
   }

   .default_bg_circle{
     @include bg_circle;
   }

   .in_place{
    @include bg_circle;
    &:hover{
        transition: 0.3s ease-in;
        @include bg_circle(var(--success20));
    }
   }

   .not_in_place{
    @include bg_circle;
    &:hover{
        transition: 0.3s ease-in;
        @include bg_circle(var(--error20));
    }
   }

   .success_circle {
        @include bg_circle(var(--success), var(--white), var(--success));
        &:hover{
            transition: 0.3s ease-in;
            @include bg_circle(#3D8C40,var(--white), #3D8C40);
        }
    }

    .cancel_circle {
        @include bg_circle(var(--error), var(--white), var(--error));
        &:hover{
            transition: 0.3s ease-in;
            @include bg_circle(#A92626, var(--white), #A92626);
        }
    }