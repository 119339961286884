ion-button > ion-badge {
    position: absolute;
    top: 0;
    transform: translateX(1rem);

    // Below styled are copied from other default ionic styles. We're making them match ion-tab-button styles
    border-radius: 8px;
    padding: 3px 2px 2px;
    min-width: 12px;
    font-size: 8px;
    font-weight: normal;
}
