// .tabs-label {
//   font-weight: 500;
//   text-transform: uppercase;
//   font-size: 1.3rem;
// }

ion-tab-bar {

  &:not([color]) {

    // Add a border but only when theres not a solid color applied through ionic
    ion-tab-button {
      
      border:5px solid  transparent;
      border-top:none;
      &.tab-selected {
        border-bottom-color: var(--highlight);
      }
    }
  }

  &.tabs-vertical {
    display: flex;
    flex-flow: column;
    height: 100%;
    align-items: stretch;
    justify-content: flex-start;
    border-top: none;

    >ion-tab-button {
      flex: 0 0 0;
      height: 6.5rem;
      min-height: 6.5rem;
    }
  }

  &.tabs-vertical:not([color]) {

    // Add a border but only when theres not a solid color applied through ionic
    ion-tab-button {
      border: solid 3px transparent;

      &.tab-selected {
        border-right-color: var(--ion-tab-bar-color-selected);
        border-bottom-color: transparent;
      }
    }
  }

  &.tabs-bottom{
    display: flex;
    flex-flow: row;
    height: 100%;
    align-items: stretch;
    justify-content: flex-start;
    border-top: none;

    >ion-tab-button {
      flex: 0 0 0;
      height: 3rem;
      min-height: 3rem;
    }
  }
}