:focus-visible {
  outline: none !important;
}
.parent-section{
    flex-grow: 1;
    min-height: 0;
    min-width: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.borderRight{
    border-right: 1px solid var(--ion-color-gray20);
  }
  .borderBottom{
    border-bottom: 1px solid var(--ion-color-gray20) !important;
  }
  .borderBottom40{
    border-bottom: 1px solid var(--ion-color-gray40) !important;
  }
  .borderTop40{
    border-top: 1px solid var(--ion-color-gray40) !important;
  }
.pointer{
    cursor: pointer;
}
.bg-white{
  background-color: #fff;
  height: 100%;
}

.bg-gray{
  background-color: var(--ion-color-gray05);
  height: 100%;
}

.mail-link{
  color: var(--ion-color-highlight);
  text-decoration: none;
}

.no-color-link{
  color: var(--ion-color-gray);
  text-decoration: none;
}
app-impersonate-user-admin,
app-pcmh-list{
  width: 100%;
}
.borderAllGray20{
  border:1px solid var(--ion-color-gray20)
}
.borderBottom05{
  border-bottom:1px solid var(--ion-color-gray05)
}
.widthMax-95{
  width: 100%;
  max-width: 92px;
}
.line-height18{
  line-height: 18px;
}
.line-height20{
  line-height: 20px;
}
.line-height22{
  line-height: 22px;
}
.line-height24{
  line-height: 24px;
}
ion-searchbar[mode=q] {
  --background: transparent;
  --border-radius: 3rem;
  --box-shadow: 0 0 0 .1rem var(--q-app--border-color);
  --cancel-button-color: var(--ion-color-primary-step-900);
  --clear-button-color: var(--ion-color-primary-step-900);
  --icon-color: var(--ion-color-primary-step-900);
}

ion-searchbar {
  --icon-color: var(--gray20) !important;
  padding: 14px 2px !important;
  margin-left: 10px;
  ::placeholder{
    font-style: italic !important;
    color: var(--gray40) !important;
  }
}

ion-searchbar.searchbar-has-focus {
  // --border-color: var(--q-color-brand) !important;
  --box-shadow: 0 0 0 .08rem var(--q-color-brand) !important;
}

.searchbar-input {
  line-height: 22px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.searchbar-search-icon.sc-ion-searchbar-md {
  left: 11px !important;
  top: 8px !important;
  width: 18px !important;
  height: 18px !important;
  color: var(--gray40) !important;
}
.searchbar-input::placeholder{
  font-weight: 300;
  font-size: 16px;
  font-family: 'Lato', sans-serif !important;
  color: var(--gray40) !important;
}
.badge{
  padding: 2px 5px;
  border-radius: 20px;
  text-align: center;
}
.blue-badge{
  background-color: var(--blue);
}
.orange-badge{
  background-color: var(--orange);
}
.lavender-badge{
  background-color: var(--lavender);
}
.teal-badge{
  background-color: var(--teal);
}
.green-badge{
  background-color: var(--green);
}
.gray-badge{
  background-color: var(--gray);
}
.darkgray-badge{
  background-color: var(--dark-gray);
}
.gray{
  color: var(--gray)
}

.display-b{
  display:block;
}
.display-inline-block{
  display: inline-block;
}


.mobileHeader{
  background-color: var(--white);
  // position: fixed;
  top:0;
  right: 0;
  left:0;
  display: none;
  @media (max-width: 767px){
      display: block;
  }
  ion-searchbar{
    padding: 10px 0px !important;
  }
}
.flex-direction-column{
  flex-direction: column;
}
.font-22{
  font-size: 22px;
}
.font-15{
  font-size: 15px;
}
.bg-gray05{
  background-color: var(--ion-color-gray05);
}
.mr-03{
  margin-right: 3px;
}
.mb-02{
  margin-bottom: 2px;
}

.jumptohr{
  margin-right: 1.25rem !important;
}
.hrBorder {
  border: 1px solid var(--gray20);
  height: 0;
  margin: 10px 0;
}
.text-ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::ng-deep{
  .popover-content {
    border-radius: 0px !important;
    --border-radius: 0 !important;
  }
}
.titleCenter{
   position: relative;
   left: 22px;
}

//Toaster
//Toaster color
.toast-info, .toast-error, .toast-success, .toast-warning{
  box-shadow: none !important;
}
.toast-container .ngx-toastr{
  padding: 11px 10px 10px 40px!important;
  min-width: 300px!important;
  max-width: 400px!important;
  width: 100%!important;
  border-radius: 5px!important;
  background-size: 20px;

  @media(max-width:767px){
  max-width: 300px!important;
  }
}
.toast-message{
  width: 80%;
  font-size: 14px;
  font-weight: 400;
}
.toast-container .toast-title {
  width: 80%;
  font-size:16px!important;
  font-weight: var(--q-font-weight-bold);
}
.toast-info {
  background-color: var(--ion-color-highlight) !important;
  color: var(--ion-color-white);
  background-image: url("../../assets/svg/download_white.svg")!important;
  background-position: 10px center!important;

}
.toast-error {
  background-color: var(--error) !important;
  color: var(--ion-color-white);
  background-image: url("../../assets/svg/Info-white.svg")!important;
  background-position: 10px center!important;
}
.toast-success {
  background-image: url("../../assets/svg/check-circle_white.svg")!important;
  background-color: var(--success) !important;
  color:var(--white)!important;
  background-position: 10px center!important;
 // background-image: url("../../assets/svg/check-circle.svg");
}

.toast-container.toast-bottom-center .ngx-toastr {
  width: 100%;
  max-width: 600px !important;
  @media(max-width: 600px) {
    max-width: 500px !important;
  }
  @media(max-width: 575px) {
    max-width: 80% !important;
  }
}

button.toast-close-button  {
    position: absolute!important;
    border: 1px solid var(--ion-color-white)!important;
    padding: 0px 5px 1px 5px !important;
    border-radius: 5px!important;
    height: 21px !important;
    width: 21px !important;
    font-size: 18px;
    font-weight: 400;
    transform: translate(-0%, -50%);
    top: 50%!important;
    right: 10px!important;
    text-shadow: none!important;
}
.toast-close-button:hover, .toast-close-button:focus {
  color :var(--ion-color-white)!important;
  text-decoration: none!important;
  cursor: pointer!important;
  opacity:10!important;
}