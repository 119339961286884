@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;1,300&display=swap');

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";


/* Local Custom App Wide */
// Please keep custom CSS specific to a certain view or component within that component's own CSS
@import './styles/variables/all';
@import './styles/triarq_custom/all';
@import './styles/ionic-custom/all';


// Kendo Custom styling
@import "./styles/kendo-style/kendo-custom.scss" 