
ion-searchbar[mode='q'] {

  --background: transparent; //	Background of the searchbar input
  --border-radius: 3rem; //	Border radius of the searchbar input
  --box-shadow: 0 0 0 .09rem var(--q-app--border-color); // Box shadow of the searchbar input
  --cancel-button-color: var(--ion-color-primary-step-900); //	Color of the searchbar cancel button
  --clear-button-color: var(--ion-color-primary-step-900); //	Color of the searchbar clear button
  // --color	Color of the searchbar text
  --icon-color: var(--ion-color-primary-step-900);	// Color of the searchbar icon
  // --placeholder-color	Color of the searchbar placeholder
  // --placeholder-font-style	Font style of the searchbar placeholder
  // --placeholder-font-weight	Font weight of the searchbar placeholder
  // --placeholder-opacity	Opacity of the searchbar placeholder
  
}
ion-searchbar[mode='md'] {

  --background: transparent; //	Background of the searchbar input
  --border-radius: 3rem; //	Border radius of the searchbar input
  --box-shadow: 0 0 0 .09rem var(--q-app--border-color); // Box shadow of the searchbar input
  --cancel-button-color: var(--ion-color-primary-step-900); //	Color of the searchbar cancel button
  --clear-button-color: var(--ion-color-primary-step-900); //	Color of the searchbar clear button
  // --color	Color of the searchbar text
  --icon-color: var(--ion-color-primary-step-900);	// Color of the searchbar icon
  // --placeholder-color	Color of the searchbar placeholder
  // --placeholder-font-style	Font style of the searchbar placeholder
  // --placeholder-font-weight	Font weight of the searchbar placeholder
  // --placeholder-opacity	Opacity of the searchbar placeholder

}
ion-searchbar[mode='ios'] {

  --background: transparent; //	Background of the searchbar input
  --border-radius: 3rem; //	Border radius of the searchbar input
  --box-shadow: 0 0 0 .09rem var(--q-app--border-color); // Box shadow of the searchbar input
  --cancel-button-color: var(--ion-color-primary-step-900); //	Color of the searchbar cancel button
  --clear-button-color: var(--ion-color-primary-step-900); //	Color of the searchbar clear button
  // --color	Color of the searchbar text
  --icon-color: var(--ion-color-primary-step-900);	// Color of the searchbar icon
  // --placeholder-color	Color of the searchbar placeholder
  // --placeholder-font-style	Font style of the searchbar placeholder
  // --placeholder-font-weight	Font weight of the searchbar placeholder
  // --placeholder-opacity	Opacity of the searchbar placeholder
}