@use 'sass:math';



$utilities:(
    "padding" : (
        "prefix" : "p",
        "values" : (
            "0": 0,
            "half" : 0.313rem,
            "1" : $base_padding,  // 10px
            "2" : $base_padding * 2, // 20px
            "3" : $base_padding * 3,
            "4" : $base_padding * 4,
            "5" : $base_padding * 5,
            "6" : $base_padding * 6,
            "7" : $base_padding * 7,
            "8" : $base_padding * 8,
            "md": 0.938rem // 15px
        ),
    ),
    "padding-left": (
        "prefix" : "pl",
        "values" : (
            "0": 0,
            "half" : 0.313rem,
            "1" : $base_padding,
            "2" : $base_padding * 2,
            "3" : $base_padding * 3,
            "4" : $base_padding * 4,
            "5" : $base_padding * 5,
            "6" : $base_padding * 6,
            "7" : $base_padding * 7,
            "8" : $base_padding * 8,
            "md": 0.938rem
        ),
    ),
    "padding-right": (
        "prefix" : "pr",
        "values" : (
            "0": 0,
            "half" : 0.313rem,
            "1" : $base_padding,
            "2" : $base_padding * 2,
            "3" : $base_padding * 3,
            "4" : $base_padding * 4,
            "5" : $base_padding * 5,
            "6" : $base_padding * 6,
            "7" : $base_padding * 7,
            "8" : $base_padding * 8,
            "md": 0.938rem
        ),
    ),
    "padding-top": (
        "prefix" : "pt",
        "values" : (
            "0": 0,
            "half" : 0.313rem,
            "1" : $base_padding,
            "2" : $base_padding * 2,
            "3" : $base_padding * 3,
            "4" : $base_padding * 4,
            "5" : $base_padding * 5,
            "6" : $base_padding * 6,
            "7" : $base_padding * 7,
            "8" : $base_padding * 8,
            "md": 0.938rem
        ),
    ),
    "padding-bottom": (
        "prefix" : "pb",
        "values" : (
            "0": 0,
            "half" : 0.313rem,
            "1" : $base_padding, 
            "2" : $base_padding * 2,
            "3" : $base_padding * 3,
            "4" : $base_padding * 4,
            "5" : $base_padding * 5,
            "6" : $base_padding * 6,
            "7" : $base_padding * 7,
            "8" : $base_padding * 8,
            "md": 0.938rem
        ),
    ),  
    "margin" : (
        "prefix" : "m",
        "values" : (
            "0": 0,
            "half" : 0.313rem,
            "1" : $base_margin,
            "2" : $base_margin * 2,
            "3" : $base_margin * 3,
            "4" : $base_margin * 4,
            "5" : $base_margin * 5,
            "6" : $base_margin * 6,
            "7" : $base_margin * 7,
            "8" : $base_margin * 8,
            "md": 0.938rem
        ),
    ),
    "margin-left" : (
        "prefix" : "ml",
        "values" : (
            "0": 0,
            "half" : 0.313rem,
            "1" : $base_margin,
            "2" : $base_margin * 2,
            "3" : $base_margin * 3,
            "4" : $base_margin * 4,
            "5" : $base_margin * 5,
            "6" : $base_margin * 6,
            "7" : $base_margin * 7,
            "8" : $base_margin * 8,
            "md": 0.938rem
        ),
    ),
    "margin-right" : (
        "prefix" : "mr",
        "values" : (
            "0": 0,
            "half" : 0.313rem,
            "1" : $base_margin,
            "2" : $base_margin * 2,
            "3" : $base_margin * 3,
            "4" : $base_margin * 4,
            "5" : $base_margin * 5,
            "6" : $base_margin * 6,
            "7" : $base_margin * 7,
            "8" : $base_margin * 8,
            "md": 0.938rem
        ),
    ),
    "margin-top" : (
        "prefix" : "mt",
        "values" : (
            "0": 0,
            "half" : 0.313rem,
            "1" : $base_margin,
            "2" : $base_margin * 2,
            "3" : $base_margin * 3,
            "4" : $base_margin * 4,
            "5" : $base_margin * 5,
            "6" : $base_margin * 6,
            "7" : $base_margin * 7,
            "8" : $base_margin * 8,
            "md": 0.938rem
        ),
    ),
    "margin-bottom" : (
        "prefix" : "mb",
        "values" : (
            "0": 0,
            "half" : 0.313rem,
            "1" : $base_margin,
            "2" : $base_margin * 2,
            "3" : $base_margin * 3,
            "4" : $base_margin * 4,
            "5" : $base_margin * 5,
            "6" : $base_margin * 6,
            "7" : $base_margin * 7,
            "8" : $base_margin * 8,
            "md": 0.938rem
        ),
    ),
    "opacity" : (
        "prefix" : "o",
        "values" : (
            "10" : 0.1,
            "20" : 0.2,
            "30" : 0.3,
            "40" : 0.4,
            "50" : 0.5,
            "60" : 0.6,
            "70" : 0.7,
            "80" : 0.8,
            "90" : 0.9,
            "100" : 1,
        ),
    ),
    "border-radius" : (
        "prefix" : "br",
        "values" : (
            "default" : $base-border-radius,
            "none" : 0,
            "xs" : math.div($base-border-radius, 4 ),
            "sm" : math.div($base-border-radius, 2 ),
            "lg" : $base-border-radius * 2,
            "full" : 50%,

        ),
    ),
    "display" : (
        "prefix" : "display",
        "values" : (
            "n" : none,
            "b" : block,
            "f" : flex,
            "i" : "inline",
            "i-b" : "inline-block",
            "g" : "grid",

        ),
    ),
    "font-size" : (
        "prefix" : "font",
        "values" : (
            "d" : $base-font-size,
            "xs" : $font-size-xs,
            "sm" : $font-size-sm,
            "md" : $font-size-md,
            "lg" : $font-size-lg,
            "xl" : $font-size-xl,
            "xxl" : $font-size-xxl,
            "xxxl" : $font-size-xxxl,
            "header" : $font-size-header,
            "text" : $font-text
        ),
    ),
    "font-weight" : (
        "prefix" : "fw",
        "values" : (
            "100" : 100,
            "300" : 300,
            "400" : 400,
            "500" : 500,
            "600" : 600,
            "700" : 700
        )
    ),
    "text-transform" : (
        "prefix" : "text",
        "values" : (
            "upper" : uppercase,
            "capital" : capitalize,
            "lower" : lowercase,
            "none" : none

        )
    ),
    "font-style" : (
        "prefix" : "fs",
        "values" : (
            "normal" : normal,
            "italic" : italic,
            "oblique" : oblique,
            "none" : none

        )
    ),

    "width" : (
        "prefix" : "w",
        "values" : (
            "auto" : auto,
            "10" : 10%,
            "15" : 15%,
            "20" : 20%,
            "25" : 25%,
            "30" : 30%,
            "35" : 35%,
            "33" : 33%,
            "40" : 40%,
            "45" : 45%,
            "50" : 50%,
            "55" : 55%,
            "60" : 60%,
            "65" : 65%,
            "70" : 70%,
            "75" : 75%,
            "80" : 80%,
            "85" : 85%,
            "90" : 90%,
            "95" : 95%,
            "100" : 100%,
        )
    )
);

@each $property, $map in $utilities{
    $prefix : map-get($map, "prefix");
    $values : map-get($map, "values");

    @each $k, $v in $values{
        @if($k == "default"){
            .#{$prefix}{
                #{$property} : $v;
            }
        }
        @else{
            .#{$prefix}-#{$k}{
                #{$property} : $v;
            }
        }
    }
}
