@use "sass:math";

$grid-columns : 12;
$grid-gaps: (
    "0" : 0,
    "1" : 10px,
    "2" : 20px,
    "3" : 30px,
);

$layout-values: flex-start, flex-end, center, space-between, space-around, space-evenly, initial, inherit;

$align-values: center, left, right, justify, initial, inherit;

$flex-item: normal, stretch, center, flex-start, flex-end, start, end, baseline, initial, inherit;

//base layout classes
.container{
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}

// .row{
//     display: flex;
//     flex-flow: row wrap;
// }

// .column{
//     display: flex;
//     flex-flow: column wrap;
// }

//grid gaps
@each $key, $val in $grid-gaps{
    .gap-#{$key} > * {
        padding: $val;
    }

    .gap-#{$key} {
        margin-left: -$val;
        margin-right: -$val;
    }
 }

 // justify content classes
@each $val in $layout-values
{
    .justify-#{$val}{
        justify-content: $val;
    }
}

// Alignment classes
@each $val in $align-values{
    .text-#{$val}{
        text-align: $val;
    }
}

// Align Items
@each $val in $flex-item{
    .align-#{$val}{
        align-items: $val;
    }
}


//col classes
@include xs{
    @for $i from 1 through $grid-columns{
        .col-#{$i}-xs{
            box-sizing: border-box;
            flex-grow: 0;
            width: math.div($i * 100%, $grid-columns);
        }
    }
}

@include sm{
    @for $i from 1 through $grid-columns{
        .col-#{$i}-sm{
            box-sizing: border-box;
            flex-grow: 0;
            width: math.div($i * 100%, $grid-columns);
        }
    }
}

@include md{
    @for $i from 1 through $grid-columns{
        .col-#{$i}-md{
            box-sizing: border-box;
            flex-grow: 0;
            width: math.div($i * 100%, $grid-columns);
        }
    }
}

@include lg{
    @for $i from 1 through $grid-columns{
        .col-#{$i}-lg{
            box-sizing: border-box;
            flex-grow: 0;
            width: math.div($i * 100%, $grid-columns);
        }
    }
}

@include xl{
    @for $i from 1 through $grid-columns{
        .col-#{$i}-xl{
            box-sizing: border-box;
            flex-grow: 0;
            width: math.div($i * 100%, $grid-columns);
        }
    }
}