:root {
  --q-font-size--header: 2.4rem;
  --q-font-weight--header: regular;
  --q-letter-spacing--header: initial;

  --q-font-size--body: 1.6rem;
  --q-font-size--body2: 1.4rem;

  --q-font-family: 'Roboto', sans-serif;

  // Material
  // --q-font-size--h1: 9rem;
  // --q-font-size--h2: 6rem;
  // --q-font-size--h3: 4.8rem;
  // --q-font-size--h4: 3.4rem;
  // --q-font-size--h5: 2.4rem;
  // --q-font-size--h6: 2rem;

    // q
  --q-font-size--h1: 3.2rem;
  --q-font-size--h2: 2.6rem;
  --q-font-size--h3: 2.2rem;
  --q-font-size--h4: 1.8rem;
  --q-font-size--h5: 1.2rem;
  --q-font-size--h6: 1.2rem;

}


// @NOTE - There is a mixin header in dependencies/mixins


body {
  font-size: var(--q-font-size--body);
  font-family: var(--q-font-family);
}

h1, h2, h3, h4, h5, h6 {
  font-size: var(--q-font-size--header);
  font-weight: var(--q-font-weight--header);
  letter-spacing: var(--q-letter-spacing--header);
}

// h1 {
//   @include header(1);
//   &.is-responsive {
//     font-size: clamp( var(--q-font-size--body) + 2rem, 5vw, var(--q-font-size--header))
//   }
// }

// h2 {
//   @include header(2);
//   &.is-responsive {
//     font-size: clamp( var(--q-font-size--body) + 1.6rem, 4vw, var(--q-font-size--header))
//   }
// }

// h3 {
//   @include header(3);
//   &.is-responsive {
//     font-size: clamp( var(--q-font-size--body) + 1.2rem, 3vw, var(--q-font-size--header))
//   }
// }

// h4 {
//   @include header(4);
//   &.is-responsive {
//     font-size: clamp( var(--q-font-size--body) + .8rem, 2.5vw, var(--q-font-size--header))
//   }
// }

// h5 {
//   @include header(5);
//   &.is-responsive {
//     font-size: clamp( var(--q-font-size--body) + .4rem, 2.3vw, var(--q-font-size--header))
//   }
// }

// h6 {

//   @include header(6);
//   &.is-responsive {
//     font-size: clamp( var(--q-font-size--body) + .2rem, 2vw, var(--q-font-size--header))
//   }
// }

small {
  font-size: var(--q-font-size--body2);
  // letter-spacing: .25px;
}


