//Your Color here
$primary : #00274e;
$primary40 : #A8C6D6;
$secondary: #a03f1d;
$gray100: #273138;
$gray40: #9DAAB0;
$gray60: #738289;
$gray80: #4B5961;
$black: #000;
$white: #fff;
$error: #d32f2f;
$warning: #f9a825;
$success: #4caf50;
$info: #2685ca;
$highlight: #007FC5;


// color palette
$colors: ("primary" : $primary,
  "secondary" : $secondary,
  "error" : $error,
  "info" : $info,
  "warning" : $warning,
  "success" : $success,
  "black" : $black,
  "white" : $white,
  "gray100" : $gray100,
  "highlight" : $highlight);


.btn-highlight-outline {
  border-color: $highlight;
  color: $highlight;
  padding: 10px 20px;
  @media (max-width: 767px) {
    padding: 6px 16px;
  }

  &:hover {
    background-color: $white;
    border-color: $highlight;
    color: $highlight;
  }

  &:focus {
    background-color: $white;
    color: $highlight;
  }

  &:active {
    background-color: $white;
    color: $highlight;
  }
}

.Filter.btn-highlight-outline{
    padding: 10px 0px 10px 20px;
    width: 150px; 
    @media (max-width: 767px){
        padding: 10px 0px 10px 20px;
        width: 150px; 
    }
    
}
.icon-btn.btn-highlight-outline{
  padding: 10px 20px 10px 10px;
  border-width: 2px;
  width: 100%;
  max-width: 220px;
}
.search.btn-highlight-outline{
    padding: 10px 20px;
    border-width: 2px;
}
.btn-highlight-outline.k-disabled{
    opacity: .5;
}
.sm-icon-btn.btn-highlight-outline{
  padding: 5px 10px;
}
.btn-highlight-solid{
    background-color: $highlight !important;
    color:$white !important;
    border-color: $highlight !important;
    padding: 10px 20px;
    @media (max-width: 767px) {
      padding: 6px 16px;
    }
}

.btn-danger-solid{
  background-color:var(--ion-color-danger) !important;
  color:var(--white) !important;
  border-color:var(--ion-color-danger) !important;
  padding: 10px 20px;
  @media (max-width: 767px) {
    padding: 6px 16px;
  }
}

.k-button-outline-base.k-disabled{
    color: $highlight !important;
}
.btn-highlight-solid.k-disabled{

    opacity: .5;
}

.form-text-input{
    background-color: $white;
    &:focus-within{
        border-color: $highlight;
    }
    &:focus{
        border-color: $highlight;
    }
    .k-input-inner{
        padding-inline: 10px;
    }
    ::placeholder{
        font-style: italic;
        font-size: 14px;
        font-weight: 300;
        color: #9DAAB0;
        
    }
}

kendo-combobox, kendo-textarea {
  ::placeholder{
    font-style: italic;
    font-size: 14px;
    font-weight: 300;
    color: #9DAAB0;
  }
}

.form-text-bg-transparent{
    background-color: transparent;
   
}
.form-check-input{
    width: 21px;
    height: 21px;
    border-color: $gray60;
    border-width: 1px;
}
.form-check-input.k-checkbox:checked, .patient_reg_main .k-checkbox.k-checked {
    border-color: #007FC5;
    color: white;
    background-color: #007FC5;
}
.form-date-input{
    &:focus{
        border-color: $highlight;
    }
}

.k-input-solid:focus,
.k-input-solid.k-focus {
  border-color: $highlight;
}

.k-input-solid:focus-within {
  border-color: $highlight;
}

.data_grid {
  thead {
    font-size: 10px;

    th {
      padding-inline: 10px !important;
      text-transform: uppercase;
    }
  }

  tbody {
    color: $gray100;
    font-size: 16px;
    font-weight: 400;

    td {
      padding: 6.5px 10px !important;

    }

    tr {
      &:hover {
        background-color: $primary40;
      }
    }

  }
}

.kendo-dialog {
  .k-window {
    width: 100%;
    max-width: 460px;
    padding: 0;
    border-radius: 10px;
    @media(max-width: 600px){
      max-width: 90%;
    }
  }

  .k-window-content {
    padding: 0px;
  }

  .kendo-body {
    padding: 20px 30px;
  }

  .k-window-titlebar-actions {
    display: none;
  }

  .k-window-titlebar {
    padding: 5px 20px;
    border-bottom: 1px solid $gray40;
  }

  kendo-dialog-titlebar {
    .k-button-icon {
      font-size: 20px;
      color: $gray80;
    }
  }

  .k-dialog-actions {
    border-top: 1px solid $gray40;
    padding: 8px 20px;
  }
}

.lg-kendo-dialog {
  .k-window {
    max-width: 750px !important;
    @media(max-width: 600px){
      padding: 10px;
      max-width: 90%;
    }
  }

  .kendo-body {
    overflow-y: auto;
    height: max-content;
    max-height: calc(95vh - 200px);
  }

  .kendo-dialog .kendo-body {
    padding: 20px 20px;
  }
}

.xs-kendo-dialog {
  .k-window {
    max-width: 400px;
    @media(max-width: 600px){
      max-width: 90%;
    }
  }

  .kendo-body {
    overflow-y: auto;
    height: max-content;
    max-height: 600px;
    padding: 0;
    @media(max-width: 600px){
      max-height: 100%;
    }
  }
  .k-overlay{
    background-color: transparent;
  }
  .kendo-close-btn{
    position: absolute;
      right: 15px;
      top: 3px;
  }
}

.sm-kendo-dialog {
  .k-window {
    max-width: 375px;
    @media(max-width: 600px){
      max-width: 90%;
    }
  }

  .kendo-body {
    overflow-y: auto;
    height: max-content;
    max-height: calc(75vh - 200px);
  }
  .k-overlay{
    background-color: transparent;
  }
}

.xl-kendo-dialog{
    .k-window{
        max-width: 972px;
        @media(max-width: 980px){
          max-width: 90%;
        }
        @media(max-width: 991px){
          max-height: 80vh;
        }
    }
    .kendo-body {
      overflow-y: auto;
      height: max-content;
      min-height: 480px;
      max-height: calc(95vh - 200px);
      @media(max-width: 980px){
        min-height: 350px;
      }
      @media(max-width: 600px){
        padding: 10px;
      }
    }
    .k-overlay{
      background-color: transparent;
    }

}
.xl-kendo-dialog.pu-details-dialog{
    .kendo-body {
      overflow-y: auto;
      height: 100%;
      min-height: max-content;
      max-height: 500px;
      @media(max-width: 980px){
        min-height: 350px;
      }
      
  }
}
.capabilities_report_dialog{
  .k-window{
    max-width: 333px;
  }
  .k-overlay{
    background-color: transparent;
  }
}
.compare_period_dialog{
  .k-window{
    max-width: 395px;
    @media (max-width: 575px) {
      max-width: 330px;
    }
  }
  .k-overlay{
    background-color: transparent;
  }
}
.action-dialog{
  .k-window{
    max-width: 300px;
  }
  .kendo-body {
      overflow-y: auto;
      height: max-content;
      min-height: 100px;
      max-height: calc(95vh - 200px);
  }
  .k-overlay{
    background-color: transparent;
  }
}
.k-grid .k-table-th,
.k-grid td,
.k-grid .k-table-td {
    border-inline-start-width:0x !important;
    border-inline-start-color: transparent !important;
}
// .k-grid .k-table-th, .k-grid td, .k-grid .k-table-td{
//     border-inline-start-width: 1px !important;
//     border-inline-start-color: #ebebea !important;
// }
.k-sort-icon{
    .k-svg-icon{
        color: #007FC5;
    }
}
.k-columnmenu-item{
    font-size: 14px;
    color:var(--gray80);
    font-weight: 500;
    display: none;
}
.k-svg-i-filter{
    display: none;
}

.k-columnmenu-item.k-selected {
  color: #007FC5;
}
.k-grid-columnmenu-popup{
    min-width: 150px;
}
kendo-grid-columnlist{
    .k-column-list{
        font-size: 16px;
    }
    .k-button-solid-primary{
        border-color: #007FC5;
        background-color: #007FC5;
    }
    .k-checkbox:checked, .k-checkbox.k-checked{
        border-color: #007FC5;
        background-color: #007FC5;
    }
}
.k-popup.k-popup-transparent{
    border-radius: 10px !important;
}
.k-tooltip{
    border-radius: 10px !important;
}
.k-progress-status{
    display: none;
}
.k-progressbar{
    background-color: var(--gray05);
}
.k-progressbar .k-selected{
    background-color: var(--success);
}
.k-progressbar-horizontal{
    height: 10px;
}
//for Button Group
.dategroupbtn{
  .k-button-group {
      box-shadow: none;
      border: 1px solid var(--gray60);
      border-radius: 5px;
      
      background-color: var(--white);
      .kendo-btn{
          border-width: 0 !important;
          margin: 1px;
          color: var(--gray-80);
          ion-text{
            @media (max-width: 1280px) {
              font-size: 14px !important;
            }
            @media (max-width: 991px) {
              font-size: 12px !important;
            }
            @media (max-width: 800px) {
              font-size: 11px !important;
            }
            @media (max-width: 767px) {
              font-size: 14px !important;
            }
          }
      }
      .k-button-md {
        @media(max-width: 1400px) {
          padding-block: 8px !important;
          padding-inline: 8px !important;
        }
        @media(max-width: 1080px) {
          padding-block: 4px !important;
          padding-inline: 4px !important;
        }
        
    }
  }
  .k-button-group>.k-button:focus,
      .k-button-group>.k-button:focus .k-button-text,
      .k-button-group>.k-button.k-focus,
      .k-button-group>.k-button:active,
      .k-button-group>.k-button.k-active,
      .k-button-group>.k-button.k-selected,
      .k-button-group>.k-button.k-selected .k-button-text {
        background-color: #007FC5 !important;
        color: #fff !important;
        border-radius: 4px;
      }
}

.k-input-solid {
background-color: transparent !important;
}

/* width */
::-webkit-scrollbar {
  height: 5px !important;
}

.k-tabstrip-content{
  &:focus {
    outline-style: none;
  }
}

.k-input-solid:hover, .k-input-solid.k-hover {
  border-bottom: 1px solid var(--gray60) !important;
}

.section_selection_list{
    kendo-label > .k-label {
      font-size: 16px;
      font-weight: 400;
      color: var(--gray80);
    }

    .k-checkbox-md {
      width: 21px;
      height: 21px;
    }
    .k-checkbox:checked, .k-checkbox.k-checked {
      border-color: var(--gray80);
      color: white;
      background-color: var(--highlight);
  }
}

.k-input-md .k-input-inner, .k-picker-md .k-input-inner {
  padding: 7px 10px 6px 10px;

}

.k-input-md .k-input-button, .k-input-md .k-spinner-increase, .k-input-md .k-spinner-decrease, .k-picker-md .k-input-button, .k-picker-md .k-spinner-increase, .k-picker-md .k-spinner-decrease {
  padding-block: 0;
  padding-inline: 4px;
}

.k-input-md .k-input-icon, .k-input-md .k-input-validation-icon, .k-input-md .k-input-loading-icon, .k-input-md .k-clear-value, .k-input-md .k-input-prefix > .k-icon, .k-input-md .k-input-prefix > .k-svg-icon, .k-input-md .k-input-suffix > .k-icon, .k-input-md .k-input-suffix > .k-svg-icon, .k-picker-md .k-input-icon, .k-picker-md .k-input-validation-icon, .k-picker-md .k-input-loading-icon, .k-picker-md .k-clear-value, .k-picker-md .k-input-prefix > .k-icon, .k-picker-md .k-input-prefix > .k-svg-icon, .k-picker-md .k-input-suffix > .k-icon, .k-picker-md .k-input-suffix > .k-svg-icon {
  padding-block: 0px;
  padding-inline: 8px;
  box-sizing: content-box;
}

.k-form{
  line-height: 24px;
}

::ng-deep{
  .k-popup{
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
    font-size: 14px !important;
  }
}

.k-grid {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

/* Enable text selection globally */
html , body , body *{
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}
